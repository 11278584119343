import React from "react"
import { Link } from "gatsby"

import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import "react-tabs/style/react-tabs.css"

import Layout from "../../../components/de/layout"
//import Image from "../components/image"
import SEO from "../../../components/de/seo"

import MainImg from "../../../images/main_bg.jpg"

import shape1 from "../../../images/dec/1.png"
import shape2 from "../../../images/dec/2.png"
import shape3 from "../../../images/dec/3.png"
import shape4 from "../../../images/dec/4.png"
import shape5 from "../../../images/dec/5.png"
import shape6 from "../../../images/dec/6.png"
import shape7 from "../../../images/dec/7.png"
import shape8 from "../../../images/dec/8.png"

import product6 from "../../../images/moonamar-bonbons-mit-schwarzkummel-kurkuma-minze.png"

const CorokotovoUlje = () => (
  <Layout>
    <SEO
      title="IMMMUNO NATURA - BONBONS MIT SCHWARZKÜMMEL, KURKUMA & MINZE"
      description="IMMMUNO NATURA Bonbons mit Schwarzkümmel, Kurkuma, Minze & Zitrone - Wohlschmeckend und wohltuend für Hals und Atemwege."
    />

    <section
      id="_heroPage"
      className="hero is-large"
      style={{ backgroundImage: `url(${MainImg})` }}
    >
      <div id="bgHero">
        <span className="shape1">
          <img src={shape1} alt="Shape 1" />
        </span>
        <span className="shape2">
          <img src={shape2} alt="Shape 2" />
        </span>
        <span className="shape3">
          <img src={shape3} alt="Shape 3" />
        </span>
        <span className="shape4">
          <img src={shape4} alt="Shape 4" />
        </span>
        <span className="shape5">
          <img src={shape5} alt="Shape 5" />
        </span>
        <span className="shape6">
          <img src={shape6} alt="Shape 6" />
        </span>
        <span className="shape7">
          <img src={shape7} alt="Shape 7" />
        </span>
        <span className="shape8">
          <img src={shape2} alt="Shape 8" />
        </span>
      </div>
      <div className="hero-body" />
    </section>

    <span className="_line" />

    <section id="_oProizvodu" className="section">
      <div className="container">
        <div className="columns">
          <div className="column">
            <div className="_slide">
              <img
                className="_img"
                src={product6}
                alt="BONBONS MIT SCHWARZKÜMMEL, KURKUMA & MINZE"
                title="BONBONS MIT SCHWARZKÜMMEL, KURKUMA & MINZE"
              />
              <div className="_info">
                <div className="_inner">
                  <span>IMMMUNO NATURA</span>
                  <div className="title">
                    Bonbons mit Schwarzkümmel, Kurkuma, Minze und Zitrone
                  </div>
                  <div className="subtitle">
                    Verpackungsgröße: 100 g / 35 Bonbons
                  </div>
                  <div className="price">3,50 €</div>
                </div>
                <div className="_buttonShop">
                  <a
                    href="https://www.mykotheke.at/produkte/komplementaere-produkte/natur-bonbons-mit-schwarzkuemmel-kurkumin-minze-und-zitrone-moonamar"
                    target="_blank"
                  >
                    ZUR BESTELLUNG
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="_pageContent" className="section">
      <span className="_dec2">
        <img src={shape8} alt="Dec 2" />
      </span>
      <div className="container">
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <Tabs>
              <TabList>
                <Tab>Produktbeschreibung</Tab>
                <Tab>Zutaten und Verwendung</Tab>
              </TabList>

              <TabPanel>
                <div className="content">
                  <p>
                    IMMMUNO NATURA Bonbons warden auf Basis von Schwarzkümmelöl,
                    Kurkuma, Minze und Zitrone gemacht.
                  </p>
                  <p>
                    Sie sind wohlschmeckend und wohltuend für Hals und Atemwege.
                  </p>
                  <p>
                    Kühl, trocken und luftdicht aufbewahren. Vor
                    Sonneneinstrahlung schützen.
                  </p>
                  <p>NICHT BEIßEN ODER SCHLUCKEN.</p>
                  <table>
                    <tbody>
                      <tr>
                        <td width="125">
                          <p>
                            <strong>Nährwerte</strong>
                          </p>
                        </td>
                        <td width="67">
                          <p>
                            <strong>pro 100 g</strong>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>Brennwert</p>
                        </td>
                        <td>
                          <p>
                            1.653 kJ
                            <br /> 389 kcal
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            Fett
                            <br />
                            <em>davon gesättigt</em>
                          </p>
                        </td>
                        <td>
                          <p>
                            0,7 g<br />
                            &#60; 0,1 g
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            Kohlenhydrate
                            <br />
                            <em>davon Zucker</em>
                          </p>
                        </td>
                        <td>
                          <p>
                            &lt; 95,6 g<br /> &lt; 59,0 g
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>Eiweiß</p>
                        </td>
                        <td>
                          <p>0,1 g</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>Salz</p>
                        </td>
                        <td>
                          <p>&lt; 0,0 g</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="content">
                  <h3>Zutaten</h3>
                  <p>
                    Zucker, Glukosesirup, Schwarzkümmelöl, natürliche
                    Pflanzenextrakte (Kurkuma-Wurzel, Karotte), natürliches
                    Pfefferminzöl, natürliches Zitronenöl, natürliches Menthol.
                  </p>
                  <p>
                    Kühl, trocken und luftdicht aufbewahren. Vor
                    Sonneneinstrahlung schützen.
                  </p>
                  <p>NICHT BEIßEN ODER SCHLUCKEN.</p>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
      <span className="_dec3">
        <img src={shape6} alt="Dec 3" />
      </span>
    </section>
  </Layout>
)

export default CorokotovoUlje
